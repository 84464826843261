import payload_plugin_ydbKQgB6te from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.0_rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_PfkuakUzE3 from "/app/node_modules/.pnpm/nuxt@3.15.0_eslint@9.17.0_rollup@4.29.1_sass@1.83.0_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tbHRX1WnQC from "/app/node_modules/.pnpm/nuxt@3.15.0_eslint@9.17.0_rollup@4.29.1_sass@1.83.0_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yc8INe9eIz from "/app/node_modules/.pnpm/nuxt@3.15.0_eslint@9.17.0_rollup@4.29.1_sass@1.83.0_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vK743RRDLf from "/app/node_modules/.pnpm/nuxt@3.15.0_eslint@9.17.0_rollup@4.29.1_sass@1.83.0_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LiSqhRWsLi from "/app/node_modules/.pnpm/nuxt@3.15.0_eslint@9.17.0_rollup@4.29.1_sass@1.83.0_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_v0Mzyz4ZMz from "/app/node_modules/.pnpm/nuxt@3.15.0_eslint@9.17.0_rollup@4.29.1_sass@1.83.0_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_G5A1PVcmbb from "/app/node_modules/.pnpm/nuxt@3.15.0_eslint@9.17.0_rollup@4.29.1_sass@1.83.0_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_wSQuF13xsc from "/app/node_modules/.pnpm/@pinia+nuxt@0.9.0_pinia@2.3.0_rollup@4.29.1/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_nZEeojvzWq from "/app/node_modules/.pnpm/nuxt@3.15.0_eslint@9.17.0_rollup@4.29.1_sass@1.83.0_typescript@5.7.2_vite@6.0.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_Mr7DuCEPMx from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_rollup@4.29.1_vite@6.0.6_vue@3.5.13/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_tPltINESe1 from "/app/node_modules/.pnpm/@hebilicious+vue-query-nuxt@0.3.0_@tanstack+vue-query@5.62.9_nuxt@3.15.0_rollup@4.29.1/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_yhNNBXo5SG from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_eslint@9.17.0_rollup@4.29.1_typescript@5.7.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_dtGDprzTJ5 from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.1_eslint@9.17.0_rollup@4.29.1_typescript@5.7.2_vue@3.5.13/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import errorCodeHandler_5jOIB6Wwtw from "/app/plugins/errorCodeHandler.ts";
import productFruits_f0XtXUbMc8 from "/app/plugins/productFruits.ts";
import stripe_6FZUdDHpIZ from "/app/plugins/stripe.ts";
import unlayer_BylDnEqkgA from "/app/plugins/unlayer.ts";
export default [
  payload_plugin_ydbKQgB6te,
  revive_payload_client_PfkuakUzE3,
  unhead_tbHRX1WnQC,
  router_yc8INe9eIz,
  payload_client_vK743RRDLf,
  navigation_repaint_client_LiSqhRWsLi,
  check_outdated_build_client_v0Mzyz4ZMz,
  chunk_reload_client_G5A1PVcmbb,
  plugin_vue3_wSQuF13xsc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_nZEeojvzWq,
  plugin_Mr7DuCEPMx,
  plugin_tPltINESe1,
  switch_locale_path_ssr_yhNNBXo5SG,
  i18n_dtGDprzTJ5,
  errorCodeHandler_5jOIB6Wwtw,
  productFruits_f0XtXUbMc8,
  stripe_6FZUdDHpIZ,
  unlayer_BylDnEqkgA
]