
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as transactionspryigttkUNMeta } from "/app/pages/[organizationId]/api/transactions.vue?macro=true";
import { default as indexGHltVpjmmdMeta } from "/app/pages/[organizationId]/audience/import-contacts/choose-method/index.vue?macro=true";
import { default as indexCG4MjOOmbaMeta } from "/app/pages/[organizationId]/audience/import-contacts/upload/[type]/index.vue?macro=true";
import { default as indexxlBQT5LdgkMeta } from "/app/pages/[organizationId]/audience/import-history/index.vue?macro=true";
import { default as indexVPEb8x9CotMeta } from "/app/pages/[organizationId]/audience/segments/index.vue?macro=true";
import { default as indexj6SqNvYRwzMeta } from "/app/pages/[organizationId]/automation/list/index.vue?macro=true";
import { default as indexbSjJ4107QLMeta } from "/app/pages/[organizationId]/automation/new/index.vue?macro=true";
import { default as indexfYEQNgYF7MMeta } from "/app/pages/[organizationId]/campaign/quick-sms/index.vue?macro=true";
import { default as dashboardz8ACBfBhFOMeta } from "/app/pages/[organizationId]/dashboard.vue?macro=true";
import { default as contactL8oYWjPG8WMeta } from "/app/pages/[organizationId]/help/contact.vue?macro=true";
import { default as index3fQSuE0gMIMeta } from "/app/pages/[organizationId]/help/index.vue?macro=true";
import { default as _91contentName_93GUv0pFIH3fMeta } from "/app/pages/[organizationId]/help/questions/[contentName].vue?macro=true";
import { default as contactsN9HzjHum8BMeta } from "/app/pages/[organizationId]/organization/contacts.vue?macro=true";
import { default as detailsOdgeyzIRceMeta } from "/app/pages/[organizationId]/organization/details.vue?macro=true";
import { default as personalizationfoIeceBfp7Meta } from "/app/pages/[organizationId]/organization/personalization.vue?macro=true";
import { default as senderDomainRzg3x6J0WaMeta } from "/app/pages/[organizationId]/organization/senderDomain.vue?macro=true";
import { default as senderSmskiuQRE8DnHMeta } from "/app/pages/[organizationId]/organization/senderSms.vue?macro=true";
import { default as suborganizationsDXgF6GmwUiMeta } from "/app/pages/[organizationId]/organization/suborganizations.vue?macro=true";
import { default as userList7FBJV41AqeMeta } from "/app/pages/[organizationId]/organization/userList.vue?macro=true";
import { default as invoicesmE5KUbnezYMeta } from "/app/pages/[organizationId]/settings/plan-and-billing/invoices.vue?macro=true";
import { default as indexUoVhFhqmrwMeta } from "/app/pages/[organizationId]/settings/plan-and-billing/overview/index.vue?macro=true";
import { default as historyZVpQWt94vQMeta } from "/app/pages/[organizationId]/settings/transactions/history.vue?macro=true";
import { default as api_45keyBLJ3UJFk6BMeta } from "/app/pages/[organizationId]/settings/user/api-key.vue?macro=true";
import { default as indextMQIFxyoDOMeta } from "/app/pages/[organizationId]/settings/user/basic-info/index.vue?macro=true";
import { default as securityKlVMs75FphMeta } from "/app/pages/[organizationId]/settings/user/security.vue?macro=true";
import { default as _91emailTemplateId_93ATQqSiZj4zMeta } from "/app/pages/[organizationId]/template/email/import-html/edit/[emailTemplateId].vue?macro=true";
import { default as index7v8HFqkpUsMeta } from "/app/pages/[organizationId]/template/email/import-html/index.vue?macro=true";
import { default as indexekis993uNsMeta } from "/app/pages/[organizationId]/template/email/index.vue?macro=true";
import { default as smsGCxQoUUFJcMeta } from "/app/pages/[organizationId]/template/sms.vue?macro=true";
import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as _91id_93xzd1NyLSDuMeta } from "/app/pages/auth/new-password/[passwordToken]/[id].vue?macro=true";
import { default as recoveryIXjTrEkYnmMeta } from "/app/pages/auth/recovery.vue?macro=true";
import { default as _91inviteId_93gbbpV6EfXgMeta } from "/app/pages/auth/register/invite/[inviteId].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as select_45organization7NEdC6GsoPMeta } from "/app/pages/select-organization.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "organizationId-api-transactions",
    path: "/:organizationId()/api/transactions",
    component: () => import("/app/pages/[organizationId]/api/transactions.vue")
  },
  {
    name: "organizationId-audience-import-contacts-choose-method",
    path: "/:organizationId()/audience/import-contacts/choose-method",
    component: () => import("/app/pages/[organizationId]/audience/import-contacts/choose-method/index.vue")
  },
  {
    name: "organizationId-audience-import-contacts-upload-type",
    path: "/:organizationId()/audience/import-contacts/upload/:type()",
    component: () => import("/app/pages/[organizationId]/audience/import-contacts/upload/[type]/index.vue")
  },
  {
    name: "organizationId-audience-import-history",
    path: "/:organizationId()/audience/import-history",
    component: () => import("/app/pages/[organizationId]/audience/import-history/index.vue")
  },
  {
    name: "organizationId-audience-segments",
    path: "/:organizationId()/audience/segments",
    component: () => import("/app/pages/[organizationId]/audience/segments/index.vue")
  },
  {
    name: "organizationId-automation-list",
    path: "/:organizationId()/automation/list",
    component: () => import("/app/pages/[organizationId]/automation/list/index.vue")
  },
  {
    name: "organizationId-automation-new",
    path: "/:organizationId()/automation/new",
    component: () => import("/app/pages/[organizationId]/automation/new/index.vue")
  },
  {
    name: "organizationId-campaign-quick-sms",
    path: "/:organizationId()/campaign/quick-sms",
    component: () => import("/app/pages/[organizationId]/campaign/quick-sms/index.vue")
  },
  {
    name: "organizationId-dashboard",
    path: "/:organizationId()/dashboard",
    component: () => import("/app/pages/[organizationId]/dashboard.vue")
  },
  {
    name: "organizationId-help-contact",
    path: "/:organizationId()/help/contact",
    component: () => import("/app/pages/[organizationId]/help/contact.vue")
  },
  {
    name: "organizationId-help",
    path: "/:organizationId()/help",
    component: () => import("/app/pages/[organizationId]/help/index.vue")
  },
  {
    name: "organizationId-help-questions-contentName",
    path: "/:organizationId()/help/questions/:contentName()",
    component: () => import("/app/pages/[organizationId]/help/questions/[contentName].vue")
  },
  {
    name: "organizationId-organization-contacts",
    path: "/:organizationId()/organization/contacts",
    component: () => import("/app/pages/[organizationId]/organization/contacts.vue")
  },
  {
    name: "organizationId-organization-details",
    path: "/:organizationId()/organization/details",
    component: () => import("/app/pages/[organizationId]/organization/details.vue")
  },
  {
    name: "organizationId-organization-personalization",
    path: "/:organizationId()/organization/personalization",
    component: () => import("/app/pages/[organizationId]/organization/personalization.vue")
  },
  {
    name: "organizationId-organization-senderDomain",
    path: "/:organizationId()/organization/senderDomain",
    component: () => import("/app/pages/[organizationId]/organization/senderDomain.vue")
  },
  {
    name: "organizationId-organization-senderSms",
    path: "/:organizationId()/organization/senderSms",
    component: () => import("/app/pages/[organizationId]/organization/senderSms.vue")
  },
  {
    name: "organizationId-organization-suborganizations",
    path: "/:organizationId()/organization/suborganizations",
    component: () => import("/app/pages/[organizationId]/organization/suborganizations.vue")
  },
  {
    name: "organizationId-organization-userList",
    path: "/:organizationId()/organization/userList",
    component: () => import("/app/pages/[organizationId]/organization/userList.vue")
  },
  {
    name: "organizationId-settings-plan-and-billing-invoices",
    path: "/:organizationId()/settings/plan-and-billing/invoices",
    meta: invoicesmE5KUbnezYMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/plan-and-billing/invoices.vue")
  },
  {
    name: "organizationId-settings-plan-and-billing-overview",
    path: "/:organizationId()/settings/plan-and-billing/overview",
    meta: indexUoVhFhqmrwMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/plan-and-billing/overview/index.vue")
  },
  {
    name: "organizationId-settings-transactions-history",
    path: "/:organizationId()/settings/transactions/history",
    meta: historyZVpQWt94vQMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/transactions/history.vue")
  },
  {
    name: "organizationId-settings-user-api-key",
    path: "/:organizationId()/settings/user/api-key",
    meta: api_45keyBLJ3UJFk6BMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/user/api-key.vue")
  },
  {
    name: "organizationId-settings-user-basic-info",
    path: "/:organizationId()/settings/user/basic-info",
    meta: indextMQIFxyoDOMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/user/basic-info/index.vue")
  },
  {
    name: "organizationId-settings-user-security",
    path: "/:organizationId()/settings/user/security",
    meta: securityKlVMs75FphMeta || {},
    component: () => import("/app/pages/[organizationId]/settings/user/security.vue")
  },
  {
    name: "organizationId-template-email-import-html-edit-emailTemplateId",
    path: "/:organizationId()/template/email/import-html/edit/:emailTemplateId()",
    component: () => import("/app/pages/[organizationId]/template/email/import-html/edit/[emailTemplateId].vue")
  },
  {
    name: "organizationId-template-email-import-html",
    path: "/:organizationId()/template/email/import-html",
    meta: index7v8HFqkpUsMeta || {},
    component: () => import("/app/pages/[organizationId]/template/email/import-html/index.vue")
  },
  {
    name: "organizationId-template-email",
    path: "/:organizationId()/template/email",
    component: () => import("/app/pages/[organizationId]/template/email/index.vue")
  },
  {
    name: "organizationId-template-sms",
    path: "/:organizationId()/template/sms",
    component: () => import("/app/pages/[organizationId]/template/sms.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "auth-new-password-passwordToken-id",
    path: "/auth/new-password/:passwordToken()/:id()",
    meta: _91id_93xzd1NyLSDuMeta || {},
    component: () => import("/app/pages/auth/new-password/[passwordToken]/[id].vue")
  },
  {
    name: "auth-recovery",
    path: "/auth/recovery",
    meta: recoveryIXjTrEkYnmMeta || {},
    component: () => import("/app/pages/auth/recovery.vue")
  },
  {
    name: "auth-register-invite-inviteId",
    path: "/auth/register/invite/:inviteId()",
    meta: _91inviteId_93gbbpV6EfXgMeta || {},
    component: () => import("/app/pages/auth/register/invite/[inviteId].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "select-organization",
    path: "/select-organization",
    component: () => import("/app/pages/select-organization.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue")
  }
]