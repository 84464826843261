export default defineNuxtPlugin(() => {
  // Código criado com referência https://github.com/unlayer/vue-email-editor/blob/master/src/components/loadScript.ts

  const defaultScriptUrl = 'https://editor.unlayer.com/embed.js?2'
  const callbacks: (() => void)[] = []
  let loaded = false

  const isScriptInjected = (scriptUrl: string) => {
    const scripts = document.querySelectorAll('script')
    let injected = false

    scripts.forEach((script) => {
      if (script.src.includes(scriptUrl)) {
        injected = true
      }
    })

    return injected
  }

  const addCallback = (callback: () => void) => {
    callbacks.push(callback)
  }

  const runCallbacks = () => {
    if (loaded) {
      let callback

      while ((callback = callbacks.shift())) {
        callback()
      }
    }
  }

  const loadScript = (callback: () => void, scriptUrl = defaultScriptUrl) => {
    addCallback(callback)

    if (!isScriptInjected(scriptUrl)) {
      const embedScript = document.createElement('script')
      embedScript.setAttribute('src', scriptUrl)
      embedScript.onload = () => {
        loaded = true
        runCallbacks()
      }
      document.head.appendChild(embedScript)
    } else {
      runCallbacks()
    }
  }

  return {
    provide: {
      unlayer: {
        loadScript,
      },
    },
  }
})
